import http from "../http-common";

class HelmetService {
  getAllHelmets(searchDTO) {
    console.log(searchDTO)
    return http.get(`/helmet/helmets`, {
		params: searchDTO
	});
  }

  get(helmetId) {
  	return http.get(`/helmet/${helmetId}`);
  }

  findByField(matchData) {
  	return http.get(`/helmet?field=${matchData}`);
  }

  addHelmet(data) {
    return http.post("/helmet/addHelmet", data);
  }

  update(data) {
  	return http.post("/helmet/updateHelmet", data);
  }
  
  uploadImage(data,helmetId) {
  	return http.postForm("/helmet/uploadImage/"+helmetId, data);
  }
  uploadAutoGeneratedImage(data,helmetId) {
  	return http.post("/helmet/uploadAutoGeneratedImage/"+helmetId, data);
  }
  generateImage(data) {
  	return http.post("/images/generate", data);
  }
}

export default new HelmetService();
