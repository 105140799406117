import axios from "axios";

export default axios.create({
  baseURL: "https://drc-ek.rocks:8080/",  //SET TO THIS baseURL when deploying to VPS
  //baseURL: "http://localhost:8088/",
  headers: {
    "Content-type": "application/json",
  },
});


