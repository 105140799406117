	<template>
		<div class="content">
			<table>
				<tr> 
					<td class="brand"><div><img src="images/karterup.jpg" alt=""></div></td>
			<hr>
				</tr> 


				<tr> 
					<td>
										<br>
					<img class="helmet-image" :alt="helmetDetails.helmetNumber" :src="`/images/${helmetDetails.imageFile}`" />
										<br><br>
					
					</td>
				</tr> 
				<tr> 
					<td class="brand">
						<div>
							<button class="love-it-button" @click="getNextHelmet()"">Love It</button>
							<button class="shelve-it-button" @click="getNextHelmet()">Shelve It</button>
						</div>
					</td>
				</tr>
			</table>
		</div>
	</template>
<script>

import HelmetService from "../services/HelmetService";
import Modal from "@/components/Modal";
import BaseButton from "@/components/BaseButton";
import BaseInput from "@/components/Inputs/BaseInput";
import NotificationTemplate from "@/pages/Notifications/NotificationTemplate";
import { Card } from "@/components/index";
import { ASelect, ASelectOption, AButton, Table, Pagination, message } from "ant-design-vue";
import { VueAutosuggest } from "vue-autosuggest";
import { UploadOutlined } from '@ant-design/icons-vue';
import Datepicker from 'vuejs-datepicker';


const tableColumns = [];
const tableData = [];
const fileList = ([]);
let file = ({});
const checked = (false);
let yearValue = ('');

export default {
  props: {
    helmetId: {
      type: String,
      required: true
    }
  },
  components: {
		Modal,
		BaseButton,
		BaseInput,
		VueAutosuggest,
		UploadOutlined,
		Datepicker,
  },
  data() {
    return {
      helmetToAdd: {},
      modal2Helmets: false,
      helmetDetails: null,


      fileList,
      checked,
	  yearValue,

    };
  },
  methods: {

    

	
	handleSwitchChange(lifAnswerId, checked) {
	    this.answers.forEach(function(answer) {
	    	if (answer.questionType == '2') {
		    	if (answer.lifAnswerId == lifAnswerId) {
					answer.answerb = checked;
					if ( checked) {
						answer.answer = "Yes";
					} else {
						answer.answer = "No";
					}
				}
	    	}
		});
	},

	async handleAddSubmitted() {
      this.modal2Helmets = false;

      const jsonData = JSON.stringify(this.helmetToAdd);
      console.log(jsonData);
      
      const res = await HelmetService.addHelmet(jsonData);

      if (res.status === 200) {
        this.$notify({
          component: NotificationTemplate,
          icon: "tim-icons icon-bell-55",
          type: "success",
          timeout: 3000,
        });
      }
	},

    async updateHelmet() {

      const jsonData = JSON.stringify(this.helmetDetails);
      const res = await HelmetService.update(jsonData);

      if (res.status === 200) {
//        this.$notify({
//          component: NotificationTemplate,
//          icon: "tim-icons icon-bell-55",
//          type: "success",
//          timeout: 3000,
//        });

//        this.modalHelmets = false;
//        this.getAllHelmets();
      }
    },

	async getNextHelmet() {
		this.helmetId++;
		this.getHelmetDetails();
	},

    async getHelmetDetails() {
      try {
			let response = await HelmetService.get(this.helmetId);
			this.helmetDetails = response.data;

			let hasAnswers = 0;

			if (hasAnswers) {			
		        this.answers.forEach(function(answer) {
		            if (answer.answer === "Yes") {
		               temp = true;
		            } else {
		               temp = false;
		            }
		        });
			}


	    
      } catch (error) {
        console.error('Error fetching helmet details:', error);
      }
    },
	formatTime(dateString) {
      if(dateString !== null){
        const date = new Date(dateString);
      return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} EST`;
      }
      // Format the date here as needed, for example:
    },  
    
 formatDate(dateString) {
    if (dateString !== null) {
	    const date = new Date(dateString);
	    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	    const day = String(date.getDate()).padStart(2, '0');
	    const monthAbbreviation = months[date.getMonth()];
	    const year = date.getFullYear();
	    return `${day} ${monthAbbreviation} ${year}`;
  	}
  	// Handle the case when dateString is null or invalid
  	return '';
    
   },

  },
  mounted() {
    this.getHelmetDetails();
  },
  computed: {
  },
  
};
</script>
<style>

.detail_view_input input {
	text-align: center;
}

.detail_view_column_1 {
	width: 250px; 
	text-align: center;
}
.detail_view_column_2 {
	min-width: 250px; 
}
.input {
	text-align: center;
}
#autosuggest__input {
  display: inline-block;
}
.autosuggest__results-container {
	position: relative;
}
.autosuggest__results {
	font-weight: 300;
	margin: 0;
	position: absolute;
	z-index: 10000001;
	border: 1px solid #e0e0e0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: white;
}
.autosuggest__results ul {
	list-style: none;
	padding-left: 0;
	margin: 0;
}
.autosuggest__results .autosuggest__results-item {
	cursor: pointer;
	padding: 5px;
}
#autosuggest ul:nth-child(1) > .autosuggest__results_title {
	border-top: none;
}
.autosuggest__results .autosuggest__results_title {
	color: gray;
	font-size: 11px;
	margin-left: 0;
	padding: 15px 13px 5px;
	border-top: 1px solid lightgray;
}
.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
.autosuggest__results-item.autosuggest__results-item--highlighted {
	background-color: #F6F6F6;
}

.helmet-image {
						height: 600px;
    		border-radius: 35px;
			}

.inline-item {
  display: inline-block;
  margin-right: 100px; /* Adjust margin as needed */
}

.switch-container {
	width: 215px; 
    text-align: right;
    margin-right: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}


.love-it-button {
	background: #1890FF;
    background-image: linear-gradient(to bottom left, #00f2c3, #1890FF, #1890FF);
    background-size: 210% 210%;
    background-position: top right;
    background-color: #1890FF;
    transition: all 0.15s ease;
    box-shadow: none;
    color: #ffffff;
    border-width: 2px;
    border: none;
    position: relative;
    overflow: hidden;
    margin: 4px 1px;
    border-radius: 0.4285rem;
	padding: 11px 40px;
    font-size: 0.875rem;
    line-height: 1.35em;
}

.shelve-it-button {
	background: #1890FF;
    background-image: linear-gradient(to bottom left, #e8e8e8, #8c8c8c, #1890FF);
    background-size: 210% 210%;
    background-position: top right;
    background-color: #1890FF;
    transition: all 0.15s ease;
    box-shadow: none;
    color: #ffffff;
    border-width: 2px;
    border: none;
    position: relative;
    overflow: hidden;
    margin: 4px 1px;
    border-radius: 0.4285rem;
	padding: 11px 40px;
    font-size: 0.875rem;
    line-height: 1.35em;
}

.brand img {
    height: 45px;
}
.brand div {
    text-align: center;
}

</style>
